import { useMemo } from 'react';
import { usePhrUnitValuePreference } from 'my-phr/hooks';
import { getConvertedReferenceRange } from 'my-phr/components/result/utils';

export default function useChartDataWithNormalRangeReference(data, topic) {
  const { getRawUnit } = usePhrUnitValuePreference();

  return useMemo(() => {
    const dataPoint = data?.[0];

    if (!dataPoint) return {};

    const { ranges } = getConvertedReferenceRange(
      topic?.ranges?.[0] ?? topic?.ranges,
      getRawUnit(dataPoint),
      dataPoint?.storyblock?.molecularWeight
    ); // using only the first range found in topic

    if (!ranges) return {};

    const normalRanges = ranges?.filter((x) => x?.flag === 'normal');

    const normalRangeBoundariesWithColor = normalRanges.reduce(
      (prev, curr) => ({
        color: prev.color ?? curr.color,
        min: Math.min(prev.min ?? Infinity, curr.min),
        max: Math.max(prev.max ?? 0, curr.max),
      }),
      {}
    );

    return normalRangeBoundariesWithColor;
  }, [data, getRawUnit, topic]);
}
