import { useEffect, useState, useCallback, memo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { isNil } from 'ramda';
import axios from 'axios';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { spacing } from 'tailwindcss/defaultTheme';

import { Roles, useAuth } from 'Auth';
import { useAds, useIsEmbedded, useMedia, useOutsideClick } from 'hooks';
import { usePageInactivity } from 'journals/hooks';
import { JournalsApi } from 'api';
import { safeHead, validEmailRegEx } from 'utils';
import { useProfile } from 'ProfileContext';
import { Banners } from 'library/components/banners';
import { BANNER_POSITION } from 'library/components/banners/Banners';
import { Alert } from 'components';
import {
  ClipboardList,
  DocumentAdd,
  DotsVertical,
  Share,
  Users,
  X,
} from 'components/icons';
import {
  ViewerAsideNav,
  ViewerLayout,
  ViewerPageNavigation,
  ViewerSidebar,
  ViewerTableOfContent,
} from 'components/viewer';
import { SEOHead } from 'components/SEO';
import { Ad, AD_SIZE } from 'components/Ad';
import { Button, Form } from 'components/forms';
import usePrevNextNavigation from 'components/viewer/ViewerPageNavigation/usePrevNextNavigation';
import { useLibraryMeta } from 'library/hooks';

import { applyDistributionConfig } from '../utils/distribution';
import {
  AddToCollectionModal,
  JournalShareModal,
  Sponsors,
} from '../components';
import {
  flattenNav,
  getJournalSlugPath,
  getPOCJournalSlugPath,
} from '../utils';
import { ContainerBlock } from '../blocks';
import { RELATED_ID, POC_SHARE_ID, viewerPath } from './const';
import { usePageMeta } from './hooks';
import Container from './Container';
import FrontPage from './FrontPage';
import Topbar from './Topbar';
import RelatedHealthJournals from './RelatedHealthJournals';

const MIN_LENGTH = 3;
const MAX_LENGTH = 200;
const MAX_MESSAGE_LENGTH = 200;

// List of container keys that are added manually
const manuallyAddedContainers = {
  [RELATED_ID]: (t) => ({
    id: RELATED_ID,
    key: RELATED_ID,
    label: t('journals.related.container'),
    complete: () => false,
    completed: false,
    rendering: true,
    slug: t('journals.related.slug'),
  }),
  [POC_SHARE_ID]: (t) => ({
    id: POC_SHARE_ID,
    key: POC_SHARE_ID,
    label: t('journals.poc.label'),
    complete: () => false,
    completed: false,
    rendering: true,
  }),
};

const renderable = (node) => node?.rendering;

function patchTocNodes(root) {
  root.id = root.key;
  root.children?.forEach(patchTocNodes);
  return root;
}

function findNodeByKey(key, root) {
  if (root.key === key) return root;
  for (const child of root?.children ?? []) {
    const node = findNodeByKey(key, child);
    if (node) return node;
  }
}

function useJournalViewer(journalKey, journalApi) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const [renderableNavNodes, setRenderableNavNodes] = useState();

  const queryConfig = {
    onError: ({ redirectUrl }) => history.replace(redirectUrl || `/404`),
  };

  const { data: journal } = useQuery(
    ['journalByKey', journalKey],
    () => journalApi.getJournalByKey(journalKey),
    {
      ...queryConfig,
      keepPreviousData: false, // update current container cache when keeping previous data
      select: applyDistributionConfig,
    }
  );
  const { data: navRoot } = useQuery(
    ['journalTocByKey', journalKey],
    () => journalApi.getJournalTocByKey(journalKey),
    {
      ...queryConfig,
      select: patchTocNodes,
    }
  );

  useEffect(() => {
    if (Boolean(journal?.preamble?.relatedJournals?.length)) {
      if (!navRoot?.children?.find((child) => child?.id === RELATED_ID)) {
        navRoot?.children.push(manuallyAddedContainers[RELATED_ID](t));
      }
    }
    if (Boolean(journal?.preamble?.parent)) {
      if (!navRoot?.children?.find((child) => child?.id === POC_SHARE_ID)) {
        navRoot?.children.push(manuallyAddedContainers[POC_SHARE_ID](t));
      }
    }
  }, [journal, navRoot, t]);

  useEffect(() => {
    setRenderableNavNodes(navRoot && flattenNav(navRoot).filter(renderable));
  }, [navRoot]);

  const firstLevelContainers = navRoot?.children?.filter(
    (x) =>
      x &&
      x?.rendering &&
      !Object.keys(manuallyAddedContainers).includes(x?.key)
  );
  const containersKeys = firstLevelContainers?.map((x) => x?.key);
  const { data: pagesMeta } = useLibraryMeta(containersKeys, {
    select: (data) => {
      if (!data) return [];
      return data.map((x) => ({
        ...x,
        url: `${url}/${viewerPath.PAGE}/${x?.slug}`,
        name: firstLevelContainers?.find((container) => container.key === x.key)
          ?.label,
      }));
    },
  });

  return { journal, navRoot, pagesMeta, renderableNavNodes };
}

function Viewer({
  journalKey,
  containerKey,
  onClose,
  onNavigate,
  journalApi = JournalsApi,
  collectionAllocation,
  isSharedJournal,
}) {
  const { t } = useTranslation();

  const [tocVisible, setTocVisible] = useState(true);
  const [shareOpen, setShareOpen] = useState(false);
  const [addToCollection, setAddToCollection] = useState();

  const adsConfig = useAds(['placements', 'journal']);

  const isEmbedded = useIsEmbedded();

  // hide ToC on mobile
  const isLarge = useMedia(useMedia.LARGE);
  useEffect(() => {
    setTocVisible(isLarge);
  }, [isLarge]);

  const closeTocOnOutsideClick = useCallback(() => {
    if (!isLarge && tocVisible) {
      setTocVisible(false);
    }
  }, [isLarge, tocVisible]);

  const tocRef = useOutsideClick(closeTocOnOutsideClick);

  const { journal, navRoot, pagesMeta, renderableNavNodes } = useJournalViewer(
    journalKey,
    journalApi
  );

  const currentPageMeta = usePageMeta(journalKey, containerKey);

  const { prev: prevNode, next: nextNode } = usePrevNextNavigation(
    renderableNavNodes,
    containerKey
  );

  const journalPrevUrl = getJournalSlugPath(journal, prevNode);
  const journalNextUrl = getJournalSlugPath(journal, nextNode);

  const journalProceedUrl = getJournalSlugPath(
    journal,
    safeHead(renderableNavNodes)
  );

  const pocPrevUrl = getPOCJournalSlugPath(journalKey, prevNode);
  const pocNextUrl = getPOCJournalSlugPath(journalKey, nextNode);
  const pocProceedUrl = getPOCJournalSlugPath(
    journalKey,
    safeHead(renderableNavNodes)
  );

  const proceedUrl = isSharedJournal ? pocProceedUrl : journalProceedUrl;
  const proceedUrlEmbed = getJournalSlugPath(
    journal,
    safeHead(renderableNavNodes),
    true
  );
  const prevUrl = isSharedJournal ? pocPrevUrl : journalPrevUrl;
  const nextUrl = isSharedJournal ? pocNextUrl : journalNextUrl;

  const navigateToNode = (node) => {
    if (renderable(node)) {
      onNavigate?.({ key: node.key, label: node.label, title: node?.title });
    }
  };

  useEffect(() => {
    if (isNil(containerKey) && journal?.currentContainerKey && navRoot) {
      const node = findNodeByKey(journal.currentContainerKey, navRoot);
      onNavigate?.(
        {
          key: journal.currentContainerKey,
          label: node?.label,
          title: node?.title,
        },
        { replace: true }
      );
    }
  }, [journal, navRoot]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isNil(journal)) return null;

  const isPOCJournal = Boolean(journal.preamble?.parent);

  const journalActions = [
    collectionAllocation && {
      onClick: () => setAddToCollection(journal),
      icon: DocumentAdd,
      label: t('journals.collections.add-to'),
    },
    !isPOCJournal && {
      onClick: () => setShareOpen(true),
      icon: Share,
      label: t('common.share'),
    },
    isPOCJournal && {
      onClick: () => setShareOpen(true),
      icon: Users,
      label: t('journals.share.share-with-team'),
    },
  ].filter(Boolean);

  const banners = journal?.preamble?.banners?.filter(
    (banner) => banner?.position === BANNER_POSITION.HJ_PAGE
  );

  return (
    <>
      <SEOHead config={currentPageMeta} />
      <JournalActivityTracker
        journalKey={journal.journalKey}
        apiCall={journalApi.addJournalAnalyticsEntry}
      />
      <ViewerLayout
        disableBackToTopButton
        sidebar={
          <ViewerSidebar>
            <ViewerSidebar.Button
              onClick={() => setTocVisible((v) => !v)}
              active={tocVisible}
              aria-label={t('labels.journals.toc')}
              aria-haspopup="true"
              aria-expanded={tocVisible}
            >
              <ClipboardList className="w-5" /> {t('common.table-of-contents')}
            </ViewerSidebar.Button>
            {journalActions.map(({ onClick, icon: Icon, label }, index) => (
              <ViewerSidebar.Button
                key={`journalAction_${index}`}
                onClick={onClick}
                aria-label={label}
                aria-haspopup="dialog"
              >
                <Icon className="w-5" />
                {label}
              </ViewerSidebar.Button>
            ))}
          </ViewerSidebar>
        }
        topbar={
          <Topbar
            journal={journal}
            onCloseClick={() => onClose()}
            onMenuToggle={() => setTocVisible((v) => !v)}
          />
        }
        aside={
          <ViewerAsideNav
            className={classnames({ hidden: !tocVisible })}
            onClose={() => setTocVisible(false)}
            ref={tocRef}
          >
            {adsConfig?.toc?.top?.enabled && (
              <Ad
                className="pb-2"
                slot={adsConfig?.toc?.top?.slot}
                dimensions={{
                  base: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_SMALL,
                }}
              />
            )}
            {navRoot && (
              <ViewerTableOfContent
                root={navRoot}
                activeId={containerKey}
                onSelect={navigateToNode}
              />
            )}
            {adsConfig?.toc?.bottom?.enabled && (
              <Ad
                className="pt-2"
                slot={adsConfig?.toc?.bottom?.slot}
                dimensions={{
                  base: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_SMALL,
                }}
              />
            )}
          </ViewerAsideNav>
        }
        main={
          <>
            {containerKey && (
              <div className="pb-8 xl:flex xl:justify-center">
                <div className="m-4 mx-auto max-w-4xl grow px-4 pb-10 lg:mx-8 lg:px-0 lg:pb-0">
                  <Sponsors logos={journal?.preamble?.logos} />
                  {adsConfig?.container?.top?.enabled && (
                    <Ad
                      className="flex h-min justify-center pb-4"
                      slot={adsConfig?.container?.top?.slot}
                      dimensions={{
                        base: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_SMALL,
                        sm: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_LARGE,
                      }}
                    />
                  )}

                  {containerKey !== RELATED_ID &&
                    containerKey !== POC_SHARE_ID && (
                      <Container
                        journalKey={journalKey}
                        containerKey={containerKey}
                        journal={journal}
                        journalApi={journalApi}
                      />
                    )}

                  <Banners items={banners} />
                  {containerKey === RELATED_ID && (
                    <RelatedHealthJournals journal={journal} />
                  )}

                  {containerKey === POC_SHARE_ID && (
                    <POCShare journal={journal} />
                  )}

                  {adsConfig?.container?.bottom?.enabled && (
                    <Ad
                      className="flex h-min justify-center pt-4"
                      slot={adsConfig?.container?.bottom?.slot}
                      dimensions={{
                        base: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_SMALL,
                        sm: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_LARGE,
                      }}
                    />
                  )}

                  <ViewerPageNavigation
                    nodes={renderableNavNodes}
                    prevLink={prevUrl}
                    nextLink={nextUrl}
                    activeId={containerKey}
                  />
                </div>

                {adsConfig?.container?.side?.enabled && (
                  <Ad
                    className="flex h-min shrink-0 justify-center py-4 xl:pr-4"
                    slot={adsConfig?.container?.side?.slot}
                    dimensions={{
                      base: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_SMALL,
                      sm: AD_SIZE.HORIZONTAL.SMART_PHONE_BANNER_LARGE,
                      xl: AD_SIZE.PORTRAIT.LARGE,
                    }}
                  />
                )}
              </div>
            )}

            {journal && isNil(containerKey) && (
              <FrontPage
                pages={pagesMeta}
                preamble={journal.preamble}
                proceedUrl={isEmbedded ? proceedUrlEmbed : proceedUrl}
                imageSectionStyle={
                  adsConfig?.page?.top
                    ? {
                        minHeight: isLarge
                          ? `calc(100vh - calc(${spacing[52]} + ${spacing[2]}))`
                          : `calc(100vh - ${spacing[40]})`,
                      }
                    : {
                        minHeight: isLarge
                          ? `calc(100vh - ${spacing[28]})`
                          : `calc(100vh - ${spacing[14]})`,
                      }
                }
              />
            )}

            <ActionsFloatingButton actions={journalActions} />
          </>
        }
      />
      <JournalShareModal
        isOpen={shareOpen}
        journal={journal}
        onClose={() => setShareOpen(false)}
        socialMediaSharing={!isPOCJournal}
        linkSharing={!isPOCJournal}
        isSharedJournal={isSharedJournal}
      />
      {collectionAllocation && (
        <AddToCollectionModal
          journal={addToCollection}
          onClose={() => setAddToCollection()}
        />
      )}
    </>
  );
}

Viewer.propTypes = {
  journalKey: PropTypes.string,
  containerKey: PropTypes.string,
  onClose: PropTypes.func,
  onNavigate: PropTypes.func,
  journalApi: PropTypes.object,
  collectionAllocation: PropTypes.bool,
};

const JournalActivityTracker = memo(
  ({ journalKey, apiCall }) => {
    // Trigger state update if no interactions were made in a set period of time (30 min by default)
    const lastInteraction = usePageInactivity();

    // send request if lastInteractionState is updated
    // no need for react-query hook, as it is supposed to be a background call, no need to increase complexity
    useEffect(() => {
      if (journalKey && apiCall) {
        apiCall(journalKey).then(
          () => console.info('Analytics entry added!'),
          () => console.warn('Failed to add Analytics entry!')
        );
      }
    }, [lastInteraction.count, journalKey, apiCall]);

    return null;
  },
  // check if the journal key has changed, or the apiCall, otherwise no need to re-render
  (prev, curr) =>
    prev.journalKey === curr.journalKey && prev.apiCall === curr.apiCall
);

function POCShare({ journal }) {
  const { t } = useTranslation();

  const [mailState, setMailState] = useState();

  const { hasRole } = useAuth();
  const isHealthProfessional = hasRole(Roles.HP);

  const [profile] = useProfile();

  const prefilledName = isHealthProfessional
    ? `${profile?.title ?? ''} ${profile.firstName} ${profile.lastName}`.trim()
    : '';

  const { mutate: shareJournal, isLoading } = useMutation(
    (data) => {
      const formData = new FormData();

      for (const [key, value] of Object.entries(data)) {
        if (value) {
          formData.append(key, value);
        }
      }

      return axios.post(
        `${process.env.REACT_APP_LIBRARY_URL}/api/share`,
        formData,
        {
          headers: {
            'x-api-secret': process.env.REACT_APP_API_SECRET,
            ...Headers.FORMDATA,
          },
        }
      );
    },
    {
      onSuccess: (res) => {
        setMailState({ type: res?.data?.status, msg: res?.data?.response });
      },
      onError: (err) => {
        setMailState({
          type: 'error',
          msg: err?.message || t('errors.default-error-page.title'),
        });
      },
    }
  );

  const parentKey = journal?.preamble?.parent?.key;

  return (
    <div className="mx-auto max-w-4xl space-y-6">
      <ContainerBlock label={t('journals.poc.label')}>
        <div className="space-y-4">
          <h2 className="text-smd-h1 font-bold leading-snug">
            {t('journals.poc.heading')}
          </h2>
          <p className="hidden text-smd-sm lg:block">
            {t('journals.poc.description')}
          </p>
        </div>
        <Form
          className="flex flex-col items-start"
          onSubmit={shareJournal}
          resetFormOnSuccess
        >
          <Form.Input
            type="hidden"
            label=""
            name="url"
            value={`${window.location.origin}/journal/${parentKey}`}
          />
          <Form.Input type="hidden" label="" name="id" value={parentKey} />
          <Form.Input
            groupClassName="flex flex-col w-full"
            label={`${t('journals.poc.sender')} *`}
            placeholder={t('journals.poc.sender-placeholder')}
            defaultValue={prefilledName}
            name="name"
            rules={{
              required: t('errors.required'),
              maxLength: {
                value: MAX_LENGTH,
                message: t('errors.max-length', {
                  field: t('journals.poc.sender'),
                  value: MAX_LENGTH,
                }),
              },
              minLength: {
                value: MIN_LENGTH,
                message: t('errors.min-length', {
                  field: t('journals.poc.sender'),
                  value: MIN_LENGTH,
                }),
              },
            }}
          />
          <Form.Input
            groupClassName="flex flex-col w-full mt-4"
            label={`${t('journals.poc.receiver-email')} *`}
            placeholder={t('journals.poc.receiver-email-placeholder')}
            name="email"
            type="email"
            rules={{
              required: t('errors.required'),
              pattern: {
                value: validEmailRegEx,
                message: t('errors.pattern', { field: t('common.email') }),
              },
            }}
          />
          <Form.TextArea
            groupClassName="flex flex-col w-full mt-4"
            className="min-h-20"
            label={t('journals.poc.message')}
            name="message"
            rules={{
              maxLength: {
                value: MAX_MESSAGE_LENGTH,
                message: t('errors.max-length', {
                  field: t('journals.poc.message'),
                  value: MAX_MESSAGE_LENGTH,
                }),
              },
            }}
          />
          <Button.Primary type="submit" loading={isLoading} className="mt-4">
            {t('common.submit')}
          </Button.Primary>
        </Form>
        {mailState && <Alert res={mailState} destroy={() => setMailState()} />}
      </ContainerBlock>
    </div>
  );
}

function ActionsFloatingButton({ actions = [] }) {
  const { t } = useTranslation();
  const [actionsVisible, setActionsVisible] = useState(false);

  const isLarge = useMedia(useMedia.LARGE);

  if (isLarge) {
    return null;
  }

  return (
    <div className="fixed bottom-2 right-5 z-50">
      {actionsVisible && (
        <div className="mb-4 flex flex-col items-end space-y-2 pr-1">
          {actions.map(({ onClick, icon: Icon, label }, index) => (
            <button
              className="relative flex items-center rounded-full bg-transparent outline-none focus-visible:shadow-smd-ring"
              key={`floatingJournalActions_${index}`}
              onClick={onClick}
            >
              <span className="absolute -left-2 -translate-x-full whitespace-nowrap rounded bg-smd-accent p-2 text-smd-xs font-semibold text-white">
                {label}
              </span>
              <Button.Primary as="span" flavor="round" tabIndex={-1}>
                <Icon className="w-5" />
              </Button.Primary>
            </button>
          ))}
        </div>
      )}
      <Button
        color={actionsVisible ? 'none' : 'primary'}
        flavor="round"
        size="lg"
        className={classnames(
          'shadow-lg',
          actionsVisible &&
            'border bg-white text-smd-accent focus-visible:shadow-smd-ring'
        )}
        onClick={() => setActionsVisible((prev) => !prev)}
        aria-label={t('labels.journals.actions')}
      >
        {actionsVisible ? (
          <X className="h-6 w-6 stroke-2" />
        ) : (
          <DotsVertical className="h-6 w-6" />
        )}
      </Button>
    </div>
  );
}

export default Viewer;
