const defaultTheme = require('tailwindcss/defaultTheme');

const animation = {
  'fade-in-down': 'fade-in-down 0.5s ease-out',
  'fade-out-down': 'fade-out-down 0.5s ease-out',
  'fade-in-up': 'fade-in-up 0.5s ease-out',
  'fade-out-up': 'fade-out-up 0.5s ease-out',
  'accordion-down': 'accordion-down 0.2s ease-out',
  'accordion-up': 'accordion-up 0.2s ease-out',
};

const colors = {
  inherit: 'inherit',
  current: 'currentColor',
  smd: {
    accent: {
      bright: 'var(--smd-tertiary)',
      lightest: 'var(--smd-secondary-light)',
      lighter: 'var(--smd-secondary-dark)',
      light: 'var(--smd-secondary)',
      DEFAULT: 'var(--smd-primary)',
      dark: 'var(--smd-primary-dark)',
      topbar: 'var(--smd-topbar)',
      tooltip: 'var(--smd-tooltip-bg)',
      card: 'var(--smd-card-bg)',
    },
    gray: {
      DEFAULT: '#A7A5A5',
      light: '#D1D1D1',
      lighter: '#E4E4E4',
      lightest: '#F5F5F5',
      dark: '#707070',
      darker: '#2B2B2B',
    },
    error: {
      DEFAULT: '#F56565',
      light: '#FEF2F2',
      dark: '#D83030',
      darker: '#D60000',
    },
    warning: '#FE7D5D',
    red: '#FE9898',
    focus: {
      primary: '#2360A5',
      secondary: '#80BBF9',
    },
    sources: {
      emr: '#3B82F6',
      wearable: '#FE7D5D',
      manual: '#1AA9B4',
    },
    status: {
      completed: '#20CC9B',
    },
  },
  border: 'hsl(var(--border))',
  input: 'hsl(var(--input))',
  ring: 'hsl(var(--ring))',
  background: 'hsl(var(--background))',
  foreground: 'hsl(var(--foreground))',
  primary: {
    DEFAULT: 'hsl(var(--primary))',
    foreground: 'hsl(var(--primary-foreground))',
  },
  secondary: {
    DEFAULT: 'hsl(var(--secondary))',
    foreground: 'hsl(var(--secondary-foreground))',
  },
  destructive: {
    DEFAULT: 'hsl(var(--destructive))',
    foreground: 'hsl(var(--destructive-foreground))',
  },
  muted: {
    DEFAULT: 'hsl(var(--muted))',
    foreground: 'hsl(var(--muted-foreground))',
  },
  accent: {
    DEFAULT: 'hsl(var(--accent))',
    foreground: 'hsl(var(--accent-foreground))',
  },
  popover: {
    DEFAULT: 'hsl(var(--popover))',
    foreground: 'hsl(var(--popover-foreground))',
  },
  card: {
    DEFAULT: 'hsl(var(--card))',
    foreground: 'hsl(var(--card-foreground))',
  },
};

// base 16px
const fontSize = {
  'smd-2xs': '0.625rem', // 10px
  'smd-xs': '0.75rem', // 12px
  'smd-sm': '0.875rem', // 14px
  'smd-base': '1rem', // 16px
  'smd-h4': '1.125rem', // 18px
  'smd-h3.5': '1.25rem', // 20px
  'smd-h3': '1.375rem', // 22px
  'smd-h2.5': '1.5rem', // 24px
  'smd-h2': '1.625rem', // 26px
  'smd-heading3': '1.875rem', // 30px
  'smd-h1': '2rem', // 32px
  'smd-t2': '2.25rem', // 36px
  'smd-t1.5': '2.5rem', // 40px
  'smd-heading1': '3rem', // 48px
  'smd-t1': '3.125rem', // 50px
};

const fontWeight = {
  bolder: '1000',
};
const keyframes = {
  'fade-in-down': {
    '0%': {
      opacity: '0',
      transform: 'translateY(-10px)',
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  'fade-out-down': {
    from: {
      opacity: '1',
      transform: 'translateY(0px)',
    },
    to: {
      opacity: '0',
      transform: 'translateY(10px)',
    },
  },
  'fade-in-up': {
    '0%': {
      opacity: '0',
      transform: 'translateY(10px)',
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  'fade-out-up': {
    from: {
      opacity: '1',
      transform: 'translateY(0px)',
    },
    to: {
      opacity: '0',
      transform: 'translateY(10px)',
    },
  },
  'accordion-down': {
    from: { height: '0' },
    to: { height: 'var(--radix-accordion-content-height)' },
  },
  'accordion-up': {
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: '0' },
  },
};

const scale = {
  1025: '1.025',
};
const spacing = {
  'smd-xxs': '0.25rem',
  'smd-xs': '0.5rem',
  'smd-sm': '0.75rem',
  'smd-base': '1rem',
  'smd-md': '1.25rem',
  'smd-lg': '1.5rem',
  'smd-xl': '2rem',
  'smd-xxl': '3rem',
  'smd-3xl': '4rem',
  'smd-sticky-spacer-small': `calc(${defaultTheme.spacing[14]} - 1px)`,
  'smd-sticky-spacer-large': `calc(${defaultTheme.spacing[28]} - 1px)`,
};

const typography = (theme) => ({
  DEFAULT: {
    css: {
      a: {
        color: theme('colors.smd.accent.DEFAULT'),
        'text-decoration': 'underline',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme('colors.smd.accent.light'),
        },
      },
      table: {
        display: 'block',
        'overflow-x': 'auto',
        'thead > tr > th': {
          'word-break': 'break-words',
        },
        'tbody > tr > td': {
          'vertical-align': 'middle',
          'min-width': '5ch',
        },
      },
      ul: {
        'li > a': {
          margin: '0px',
        },
      },
      blockquote: {
        color: theme('colors.smd.gray.darker'),
      },
      img: {
        margin: '0',
      },
      h2: {
        color: theme('colors.smd.gray.darker'),
      },
      h3: {
        color: theme('colors.smd.gray.darker'),
      },
      strong: {
        color: theme('colors.smd.gray.darker'),
      },
      'strong a': {
        'font-weight': 'bold',
      },
    },
  },
  'no-anchors': {
    css: {
      a: {
        color: 'inherit',
        'text-decoration': 'none',
        cursor: 'auto',
        'word-break': 'break-word',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});
// Tabs shadows
const tabsInsetShadow = `inset 0 -2px 0 0 ${colors.smd.gray.lighter}`;
// Button shadows
const tertiaryBtnShadow = `inset 0 0 0 0.094rem ${colors.smd.accent.DEFAULT}`;
const negativeBtnShadow = `inset 0 0 0 0.094rem white`;
const focusInnerRing = `0 0 0 0.075rem ${colors.smd.accent.DEFAULT}90`;
const focusOuterRing = ` 0 0 0 0.275rem ${colors.smd.accent.DEFAULT}50`;
const focusInnerAlertRing = `0 0 0 0.075rem ${colors.smd.error.dark}90`;
const focusOuterAlertRing = ` 0 0 0 0.275rem ${colors.smd.error.dark}50`;

const boxShadow = {
  smd: '0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03)',
  'smd-inset': tertiaryBtnShadow,
  'smd-inset-negative': negativeBtnShadow,
  'smd-ring': `${focusInnerRing}, ${focusOuterRing}`,
  'smd-alert-ring': `${focusInnerAlertRing}, ${focusOuterAlertRing}`,
  'smd-layered-ring': `${tertiaryBtnShadow}, ${focusInnerRing}, ${focusOuterRing}`,
  'smd-tabs-full-width': tabsInsetShadow,
  'smd-outer': '0 0 .75rem 0 rgba(0,0,0,0.25)',
  'smd-glossary': '0px 1px 3px #00000029',
};

module.exports = {
  animation,
  boxShadow,
  colors,
  defaultTheme,
  fontSize,
  fontWeight,
  keyframes,
  scale,
  spacing,
  typography,
};
