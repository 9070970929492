import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import { trim } from 'ramda';

import { useAuth } from 'Auth';
import { useOnEsc, useMedia, useOutsideClick } from 'hooks';

import { SearchSuggestions } from 'components';
import { useScroll } from 'components/Scroll';
import { Search } from 'components/icons';
import { LibraryPublicApiProvider } from 'library';

function GlobalSearchInput({ className }) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { authenticated } = useAuth();

  const fetchBeforeSearch = !useRouteMatch('/search/:resourceType');

  const isLarge = useMedia(useMedia.LARGE);

  const { isScrolled } = useScroll();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [search, setSearch] = useState('');

  const inputRef = useRef();
  const ref = useOutsideClick(() => showSearchBar && setShowSearchBar(false));

  useEffect(() => {
    setSearch('');
  }, [location]);

  useEffect(() => {
    if (isLarge && isScrolled) {
      setShowSearchBar(false);
    }
  }, [isLarge, isScrolled]);

  const handleOnSearch = async (searchTerm) => {
    const trimmedSearch = trim(searchTerm);
    const hasSearchTerm = Boolean(trimmedSearch.length);

    if (!hasSearchTerm) {
      inputRef.current?.input?.focus();
      return;
    }

    let pathname = location.pathname;

    if (fetchBeforeSearch) {
      pathname = '/search/all';
    }

    history.push({
      pathname,
      search: `query=${trimmedSearch}`,
    });
    setShowSearchBar(false);

    inputRef.current?.input?.blur();
  };

  useOnEsc(() => {
    if (showSearchBar) {
      setShowSearchBar(false);
    }
  });

  const wrapperClassName = [
    'h-10 max-w-2xl mx-auto',
    'before:absolute before:-inset-x-4 before:-z-10 before:bg-white',
    isScrolled || !isLarge ? 'before:-inset-y-2' : 'before:-inset-y-8',
    'transition-all',
  ];

  const searchComponent = (
    <SearchSuggestions
      value={search}
      onChange={setSearch}
      onSearch={handleOnSearch}
      autoFocus={showSearchBar}
      placeholder={t('library.search.search-topics-drugs')}
      wrapperClassName={wrapperClassName}
      btnClassName="bg-white group-focus-within:bg-smd-accent group-focus-within:text-white"
      testId="topNavigationBarSearchField"
      renderSuggestionsContainer={(props) => (
        <SearchSuggestions.SuggestionContainer
          {...props}
          className="absolute left-0 right-0 top-12 mx-auto max-h-52 w-full max-w-2xl"
        />
      )}
      ref={inputRef}
    />
  );

  const Wrapper = authenticated ? Fragment : LibraryPublicApiProvider;

  return (
    <Wrapper>
      <div
        className={classnames(
          className,
          'group relative flex w-10 justify-center'
        )}
      >
        <button
          className="group inline-block cursor-pointer focus-visible:outline-none"
          onClick={() => setShowSearchBar(true)}
          aria-label={t('labels.common.search-button-empty')}
        >
          <Search className="smd-group-focus-visible-primary h-6 w-6 rounded-sm stroke-2" />
        </button>
        {showSearchBar && (
          <div
            className={classnames(
              !isScrolled && isLarge ? 'inset-x-0 inset-y-6' : 'inset-0',
              'fixed z-50 bg-black bg-opacity-50 px-4 py-2'
            )}
          >
            <div ref={ref}>{searchComponent}</div>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default GlobalSearchInput;
