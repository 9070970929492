import { Button } from 'components/forms';

export const BANNER_POSITION = {
  HJ_PAGE: 'hjPageBottom',
  HERO: 'hero',
};

function Banner({ content }) {
  const { description, relatedUrl, logoUrl, alt, ctaText } = content ?? {};

  if (!content) return null;

  return (
    <div className="my-2 flex w-full flex-col items-center justify-between gap-2 bg-smd-accent-light p-6 md:flex-row">
      <div className="flex flex-col items-center gap-4 pb-2 md:flex-row md:pb-0">
        <img src={logoUrl} alt={alt} className="max-w-40 md:max-w-24" />
        <div className="text-smd-sm font-semibold">{description}</div>
      </div>
      <a
        href={relatedUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="shrink-0"
        tabIndex={-1}
      >
        <Button.Primary>{ctaText}</Button.Primary>
      </a>
    </div>
  );
}

function Banners({ items }) {
  if (!items?.length) {
    return null;
  }

  return (
    <ul className="space-y-4 p-4">
      {items?.map((banner, index) => (
        <li key={index}>
          <Banner content={banner} />
        </li>
      ))}
    </ul>
  );
}

export default Banners;
