import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useURL, useMedia } from 'hooks';

import { Backdrop } from 'components';

import { NewsletterModal } from 'components/Newsletter';
import { useScroll } from 'components/Scroll';
import { useAuth } from 'Auth';

import { useUserSubscriptionStatus } from 'my-phr/hooks';
import { getDistributionConfig } from 'distribution';

import useMenu from './useMenu';
import ListMenu from './ListMenu';
import GlobalSearchInput from './GlobalSearchInput';

import BackToMainButton from './BackToMainButton';
import StoryMDHealthButton from './StoryMDHealthButton';

import useEnabledFeatures from './useEnabledFeatures';
import {
  BetaDropDown,
  ListMenuItems,
  MenuLogo,
  MenuNotifier,
  MenuUser,
} from './MenuItems';
import AuthMenuItems, { SignInButton } from './MenuItems/AuthMenuItems';
import MainMenuItems from './MenuItems/MainMenuItems';
import useMenuRefs from './useMenuRefs';

const NEWSLETTER_QUERIES = {
  SUBSCRIBE: 'subscribe',
  SHOW: 'show',
};

export function Menu({ profile, admin }) {
  const { t } = useTranslation();

  const isLargeScreen = useMedia(useMedia.LARGE);

  const { isScrolled } = useScroll();

  const [state, dispatch] = useMenu();
  const { isListOpen, showBackdrop } = state;

  const {
    isCommunitiesEnabled,
    isSignUpEnabled,
    isStaticPagesEnabled,
    showBeta,
  } = useEnabledFeatures();

  const { userMenuRef, notificationsRef, listMenuRef } = useMenuRefs(
    state,
    dispatch
  );

  const { authenticated } = useAuth();
  const { isFetched: isFetchedSubscriptionStatus } =
    useUserSubscriptionStatus();

  const hasBackButton = getDistributionConfig([
    'backButton',
    'backToMainButton',
  ]);

  const query = useURL.useGetURLQuery();

  const forceNewsletter = Boolean(
    query.get('newsletter') === NEWSLETTER_QUERIES.SUBSCRIBE
  );
  const showNewsletter = Boolean(
    query.get('newsletter') === NEWSLETTER_QUERIES.SHOW
  );
  const [newsLetterModal, setNewsletterModal] = useState(
    forceNewsletter || showNewsletter
  );
  const clearURLQueries = useURL.useDeleteAllURLQuery();

  const showStoryMDHealthButton = !authenticated || isFetchedSubscriptionStatus;

  const listMenuToggle = (
    <ListMenu.Toggle
      open={isListOpen}
      onClick={() =>
        dispatch({
          type: isListOpen ? 'close' : 'open',
          menu: 'isListOpen',
        })
      }
    />
  );

  const logoClassName = classnames('2xs:h-6 h-5', isScrolled ? '' : 'lg:h-12');

  return (
    <nav aria-label={t('labels.common.nav-primary')}>
      <Backdrop
        className={classnames(
          showBackdrop
            ? 'opacity-100 transition-opacity delay-0 ease-out'
            : 'hidden opacity-0'
        )}
        onClick={() => dispatch({ type: 'close' })}
      />
      <div
        className={classnames(
          isScrolled ? 'h-14' : 'lg:h-28 lg:items-center lg:pt-2',
          'fixed top-0 z-30 flex w-full justify-between bg-white text-smd-main shadow-md lg:z-50'
        )}
      >
        <a
          href="#app-content"
          className="pointer-events-none absolute left-2 top-2 z-50 px-4 py-3 opacity-0 focus-visible:bg-smd-accent focus-visible:text-white focus-visible:opacity-100"
        >
          {t('main-menu.skip-to-content')}
        </a>
        <div className="flex items-center space-x-4 px-1 2xs:px-4">
          <div className="inline-flex flex-nowrap">
            <MenuLogo className={logoClassName} isScrolled={isScrolled} />
            {showBeta && (
              <BetaDropDown
                state={state}
                dispatch={dispatch}
                isScrolled={isScrolled}
              />
            )}
          </div>
          {isLargeScreen && (
            <div className="grid grid-flow-col gap-2">
              <MainMenuItems
                dispatch={dispatch}
                hasBackButton={hasBackButton}
                admin={admin}
              />
            </div>
          )}
        </div>
        <div
          className={classnames('flex items-center space-x-2', {
            'pr-4': !hasBackButton || !isScrolled || !isLargeScreen,
          })}
        >
          {showStoryMDHealthButton && isLargeScreen && (
            <div className="pr-6">
              <StoryMDHealthButton />
            </div>
          )}
          <GlobalSearchInput
            className={classnames(isScrolled ? 'h-full' : 'h-14')}
          />
          {hasBackButton && (
            <BackToMainButton className="hidden truncate md:block" />
          )}
          {process.env.REACT_APP_NOTIFICATIONS_STATE === 'ENABLED' &&
            profile &&
            isSignUpEnabled &&
            isCommunitiesEnabled && (
              <div ref={notificationsRef}>
                <MenuNotifier
                  state={state}
                  dispatch={dispatch}
                  isScrolled={isScrolled}
                />
              </div>
            )}
          {profile && isSignUpEnabled && (
            <div ref={userMenuRef}>
              <MenuUser
                profile={profile}
                state={state}
                dispatch={dispatch}
                isScrolled={isScrolled}
              />
            </div>
          )}
          {!profile && isSignUpEnabled && (
            <SignInButton isScrolled={isScrolled} />
          )}
          {isStaticPagesEnabled && listMenuToggle}
          {!isStaticPagesEnabled && !isLargeScreen && listMenuToggle}
        </div>
        {isListOpen && isLargeScreen && isStaticPagesEnabled && (
          <nav
            aria-label={t('labels.common.nav-secondary')}
            ref={listMenuRef}
            className={classnames(
              'absolute right-0 w-full bg-white lg:right-4 lg:m-1 lg:max-w-[20rem] lg:border lg:border-smd lg:shadow-md',
              isScrolled ? 'top-12' : 'top-20'
            )}
          >
            <div>
              <AuthMenuItems profile={profile} isScrolled={isScrolled} />
              <ListMenu>
                <ListMenuItems setNewsletterModal={setNewsletterModal} />
              </ListMenu>
            </div>
          </nav>
        )}
        {isListOpen && !isLargeScreen && (
          <nav
            aria-label={t('labels.common.nav-secondary')}
            className="absolute right-0 mt-14 flex h-screen w-full max-w-[20rem] flex-col space-y-4 overflow-auto bg-white p-4 text-smd-h4"
            style={{ height: 'calc(100vh - 3.5rem)' }}
          >
            <AuthMenuItems profile={profile} isScrolled={isScrolled} />
            {showStoryMDHealthButton && <StoryMDHealthButton />}
            <div className="grid gap-2 border-b-2 pb-3 font-semibold">
              <MainMenuItems
                dispatch={dispatch}
                hasBackButton={hasBackButton}
                admin={admin}
              />
            </div>
            {isStaticPagesEnabled && <ListMenuItems />}
          </nav>
        )}
      </div>
      <NewsletterModal
        isOpen={newsLetterModal}
        forceOpen={forceNewsletter}
        onRequestClose={() => {
          if (forceNewsletter || showNewsletter) {
            clearURLQueries();
          }
          setNewsletterModal(false);
        }}
      />
    </nav>
  );
}

export default Menu;
