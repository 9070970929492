import { useEffect, useRef } from 'react';

const html = document.querySelector('html');

function useOutsideClick(onClick = () => {}, eventType = 'click', options) {
  const ref = useRef();

  useEffect(() => {
    function handleClick(e) {
      // Prevent triggering the event when clicking outside a Shadcn UI dropdowns
      const isTargetHtml = e.target === html;
      const isTargetOutsideRef = ref.current && !ref.current.contains(e.target);
      const isOnClickEnabled = isTargetOutsideRef && !isTargetHtml;

      if (isOnClickEnabled) {
        onClick(e);
      }
    }

    document.addEventListener(eventType, handleClick, options);
    return () => document.removeEventListener(eventType, handleClick, options);
  }, [onClick, eventType, options]);

  return ref;
}

export default useOutsideClick;
