import React from 'react';
import PropTypes from 'prop-types';
import { head, isNil, last } from 'ramda';
import classnames from 'classnames';

import { useMedia } from 'hooks';
import { getAssetUrl } from 'utils';

import { range } from './types';
import {
  calcRangePlacement,
  findRangeForValue,
  linearScale,
  logarithmicScale,
  snapToClosestStepInRange,
  snapToRangeCenter,
} from './utils';

import styles from './RangeSlider.module.css';

// thumbnail widths in px from the stylesheet
//   needed to calculate position error
const THUMB_WIDTH = 18;
const THUMB_WIDTH_MOBILE = 28;

export function hasRangeIcons(items) {
  return items?.some((range) => range.icon);
}

export function isRenderTypeIcon(topic) {
  const topicToMatch = topic?.[0] ? topic?.[0] : topic;

  return (
    topicToMatch?.fields?.find((field) => field?.inputType === 'result')
      ?.fields?.[0]?.renderType === 'icon' ||
    topicToMatch?.fields?.find((field) => field?.renderType === 'icon')
  );
}

const Labels = React.memo(function Labels({
  rangePlacements,
  centered = false,
  labelFormatter,
  isTopicPage,
}) {
  const isSmall = useMedia(useMedia.SMALL);
  return (
    <div className="text-smd-xs uppercase text-smd-gray-dark">
      {rangePlacements.map(([range, placement], index) => {
        if (!centered && index === 0) return null;

        const { position, error } = placement;

        let left = `calc(${position.left}% + ${error.start}px)`;
        let right = centered
          ? `calc(${position.right}% - ${error.end}px)`
          : 'unset';

        let translate = centered ? '' : 'transform -translate-x-1/2';

        const orientation =
          range.label?.orientation === 'vertical'
            ? 'flex items-center writing-vertical '
            : 'flex justify-center';

        const displayLabel = range.label?.content || range.min;
        const isNumericLabel = typeof displayLabel === 'number';
        const isIconLabel = Boolean(range?.icon);

        return (
          <div
            key={index}
            className={classnames(
              isIconLabel ? '' : 'truncate',
              `absolute ${translate} ${orientation}`
            )}
            style={{
              left,
              right,
            }}
          >
            <span
              className={classnames(
                isNumericLabel &&
                  !isIconLabel &&
                  'max-h-[8ch] max-w-[8ch] truncate'
              )}
            >
              {isIconLabel ? (
                <div className="flex w-10 flex-col items-center space-y-2 rounded-[4px] border-2 border-transparent pb-1 text-center text-smd-xs sm:h-full sm:w-24 sm:pt-2">
                  <div className="h-10 pt-2 sm:pt-0">
                    <img
                      alt={range?.output?.result}
                      src={getAssetUrl(range?.icon)}
                    />
                  </div>
                  {isSmall && (
                    <span
                      className={classnames(
                        isTopicPage ? 'max-w-2/3' : '',
                        'flex flex-wrap'
                      )}
                    >
                      {range?.output?.resultShort}
                    </span>
                  )}
                </div>
              ) : labelFormatter ? (
                labelFormatter(displayLabel)
              ) : (
                displayLabel
              )}
            </span>
          </div>
        );
      })}
    </div>
  );
});

const Slider = React.memo(function Slider({ rangePlacements }) {
  return (
    <div className="relative h-1.5 w-full overflow-hidden rounded">
      {rangePlacements.map(([range, placement], index) => {
        const { position, error } = placement;

        let left = '0';
        let right = '0';
        let border = '';

        if (index > 0) {
          left = `calc(${position.left}% + ${error.start}px)`;
          border = 'border-l border-white';
        }

        if (index < rangePlacements.length - 1) {
          right = `calc(${position.right}% - ${error.end}px)`;
        }

        return (
          <div
            key={index}
            className={`absolute ${border}`}
            style={{
              left,
              right,
              top: 0,
              bottom: 0,
              backgroundColor: range.color,
            }}
          />
        );
      })}
    </div>
  );
});

function RangeSlider({ isTopicPage, ...props }) {
  const { ranges, step } = props;
  const { onChange, value } = props;
  const {
    logScale,
    centerLabels,
    snapToCenter,
    labelFormatter,
    displayLabels = true,
  } = props;

  const thumb = useMedia(useMedia.LARGE) ? THUMB_WIDTH : THUMB_WIDTH_MOBILE;

  const { apply, unapply } = logScale ? logarithmicScale : linearScale;

  const snap = snapToCenter
    ? snapToRangeCenter?.(ranges)
    : snapToClosestStepInRange;

  const min = head(ranges)?.min;
  const max = last(ranges)?.max;
  const rangePlacements = ranges?.map((range) => {
    const placement = calcRangePlacement(
      {
        min: apply(min, max, range.min),
        max: apply(min, max, range.max),
      },
      min,
      max,
      thumb
    );
    return [range, placement];
  });

  const snappedValue = snap(min, max, step, value);
  if (isNil(snappedValue)) return null;
  const inputValue = apply(min, max, snappedValue);
  const range = findRangeForValue(ranges, snappedValue);

  const handleChange = (inputValue) => {
    const newValue = unapply(min, max, inputValue);
    const newSnappedValue = snap(min, max, step, newValue);
    if (!newSnappedValue) return null;
    if (newSnappedValue !== snappedValue) {
      onChange?.(newSnappedValue, findRangeForValue(ranges, newSnappedValue));
    }
  };

  const hasIcons = hasRangeIcons(ranges);

  return (
    <div
      className={classnames(
        hasIcons ? 'h-16 sm:h-28' : 'h-10 md:h-8',
        'relative flex w-full flex-col border-t-10 border-transparent md:border-t-5'
      )}
      style={{ color: range?.color }}
    >
      <input
        type="range"
        min={min}
        max={max}
        step={(step ?? 1) / 100}
        value={inputValue}
        onChange={({ target: { value } }) => handleChange(parseFloat(value))}
        className={classnames(
          styles.slider,
          'smd-focus-visible-primary z-10 rounded-sm'
        )}
        onKeyDown={(e) => {
          const { value } = e.target;
          if (e.key === 'ArrowRight') {
            return handleChange(parseFloat(value) + step);
          }
          if (e.key === 'ArrowLeft') {
            return handleChange(parseFloat(value) - step);
          }
        }}
      />
      <div className="absolute inset-0 flex">
        <Slider rangePlacements={rangePlacements} />
      </div>
      {displayLabels && (
        <div className="relative translate-y-3 transform lg:translate-y-2">
          <Labels
            rangePlacements={rangePlacements}
            centered={centerLabels}
            labelFormatter={labelFormatter}
            isTopicPage={isTopicPage}
          />
        </div>
      )}
    </div>
  );
}

RangeSlider.propTypes = {
  ranges: PropTypes.arrayOf(range).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  logScale: PropTypes.bool,
  centerLabels: PropTypes.bool,
  snapToCenter: PropTypes.bool,
};

RangeSlider.defaultProps = {
  step: 1,
  logScale: false,
  centerLabels: false,
  snapToCenter: false,
};

export default RangeSlider;
