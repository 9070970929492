import {
  __,
  assocPath,
  defaultTo,
  mapObjIndexed,
  modifyPath,
  pathOr,
} from 'ramda';
import { getDistributionConfig } from 'distribution';

export function getDistributionLogos(journalKey) {
  return getDistributionConfig(['journals', 'logos', journalKey]);
}

export function getDistributionBanners(journalKey) {
  return getDistributionConfig(['journals', 'banners', journalKey]);
}

export function getDistributionJourneyBanners(journeyId) {
  return getDistributionConfig(['journeys', 'banners', journeyId]);
}

export function getDistributionStoryBanners(storyId) {
  return getDistributionConfig(['stories', 'banners', storyId]);
}

export function applyDistributionConfig(journal) {
  const distributionLogos = getDistributionLogos(journal?.journalKey);
  const distributionBanners = getDistributionBanners(journal?.journalKey);

  const journalWithLogos = modifyPath(
    ['preamble', 'logos'],
    defaultTo(__, distributionLogos),
    journal
  );

  return assocPath(
    ['preamble', 'banners'],
    pathOr(distributionBanners, ['preamble', 'banners'], journalWithLogos),
    journalWithLogos
  );
}

export function applyDistributionConfigToJourney(journey) {
  return mapObjIndexed((singleJourney, journeyId) => {
    const distributionBanners = getDistributionJourneyBanners(journeyId);

    return assocPath(['banners'], distributionBanners, singleJourney);
  }, journey);
}
