import React from 'react';
import { Controller } from 'react-hook-form';

import {
  SelectContent,
  SelectItem,
  SelectShadcn,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

export function CustomSelectInput(props) {
  const {
    options,
    ItemComponent = DefaultCustomSelectItem,
    ValueComponent = DefaultCustomValueComponent,
  } = props;

  return (
    <SelectShadcn
      defaultValue={props.defaultValue}
      className="w-[9.5rem] max-w-[11.5rem] md:w-full"
      {...props}
    >
      <SelectTrigger className="h-[42px] rounded-none border-smd-gray-light">
        <SelectValue placeholder="Units" asChild>
          <ValueComponent value={props.value} options={options} />
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            <ItemComponent option={option} />
          </SelectItem>
        ))}
      </SelectContent>
    </SelectShadcn>
  );
}

export function ControlledCustomSelectInput({
  name,
  control,
  defaultValue,
  options,
  ...rest
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <CustomSelectInput options={options} {...rest} {...field} />
      )}
    />
  );
}

function DefaultCustomSelectItem(props) {
  return <div>{props.option.value}</div>;
}

function DefaultCustomValueComponent(props) {
  return <div>{props.value}</div>;
}
